<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div style="height: 100%">
        <div style="margin-bottom: 20px">
          <el-button
            v-if="addRoot"
            type="success"
            icon="el-icon-plus"
                size="small"
            @click="handleAddRoot"
            >新增根菜单</el-button
          >
          <el-button
            v-if="disable"
            type="warning"
            icon="el-icon-video-play"
                size="small"
            @click="handleDisable"
            >禁用</el-button
          >
          <el-button
            v-if="enable"
            type="success"
            icon="el-icon-video-pause"
                size="small"
            @click="handleEnable"
            >启用</el-button
          >
          <el-button
            v-if="deleteButton"
            type="danger"
            icon="el-icon-delete"
                size="small"
            @click="handleDelete"
            >删除</el-button
          >
        </div>

        <div style="height: calc(100% - 60px)">
          <el-table
            :data="permissionList"
            v-loading="tableLoading"
            row-key="id"
            stripe
            border
            default-expand-all
            height="100%"
            style="width: 100%"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              fixed
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              fixed
              prop="name"
              label="权限名称"
              width="240"
            ></el-table-column>
            <el-table-column
              prop="code"
              label="权限编码"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="path"
              label="权限路径"
              width="260"
            ></el-table-column>
            <el-table-column
              prop="sortNum"
              label="排序"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column prop="permissionType" label="权限类型" width="120" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.permissionType === 2" type="success" 
                size="small"
                  >菜单</el-tag
                >
                <el-tag v-else type="warning" 
                size="small">按钮</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isDisabled" label="是否禁用" width="120" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isDisabled === 0" type="success"
                size="small"
                  >启用</el-tag
                >
                <el-tag v-else type="danger">禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
            ></el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="450"
              align="center"
            >
              <template slot-scope="scope">
                <div
                  class="my-button"
                  v-if="addSub && scope.row.permissionType === 2"
                  @click="handleAddSub(scope.row)"
                >
                  新增子菜单
                </div>
                <div
                  class="my-button"
                  v-if="addButtonButton && scope.row.permissionType === 2"
                  @click="handleAddButton(scope.row)"
                >
                  新增按钮
                </div>
                <div
                  class="my-button"
                  v-if="viewButton && scope.row.permissionType === 3"
                  @click="handleViewButton(scope.row.id)"
                >
                  查看
                </div>
                <div
                  class="my-button-green"
                  v-if="updateButtonButton && scope.row.permissionType === 3"
                  @click="handleUpdateButton(scope.row.id)"
                >
                  修改
                </div>
                <div
                  class="my-button"
                  v-if="viewMenu && scope.row.permissionType === 2"
                  @click="handleViewMenu(scope.row.id)"
                >
                  查看
                </div>
                <div
                  class="my-button"
                  v-if="updateMenuButton && scope.row.permissionType === 2"
                  @click="handleUpdateMenu(scope.row.id)"
                >
                  修改
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog
          :title="
            menuDialogType === 1
              ? '新增菜单'
              : menuDialogType === 2
              ? '查看菜单'
              : '修改菜单'
          "
          :visible.sync="menuDialogVisible"
          width="600px"
        >
          <el-form
            ref="menuForm"
            :model="menuForm"
            :rules="menuRules"
            label-width="80px"
          >
            <el-form-item label="菜单名称" prop="name">
              <el-input
                type="text"
                v-model="menuForm.name"
                placeholder="请输入菜单名称"
                :disabled="menuDialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="菜单编码" prop="code">
              <el-input
                type="text"
                v-model="menuForm.code"
                placeholder="请输入菜单编码"
                :disabled="menuDialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="菜单路径" prop="path">
              <el-input
                type="text"
                v-model="menuForm.path"
                placeholder="请输入菜单路径"
                :disabled="menuDialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sortNum">
              <el-input-number
                v-model="menuForm.sortNum"
                :min="0"
                placeholder="请输入排序"
                :disabled="menuDialogType === 2"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="是否禁用" prop="isDisabled">
              <el-switch
                v-model="menuForm.isDisabled"
                :active-value="1"
                :inactive-value="0"
                :disabled="menuDialogType === 2"
              ></el-switch>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                v-model="menuForm.remark"
                placeholder="请输入备注"
                :disabled="menuDialogType === 2"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="menuDialogVisible = false">取 消</el-button>
            <el-button
              v-if="menuDialogType !== 2"
              type="primary"
              @click="handleConfirmMenu"
              :loading="menuConfirmLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>

        <el-dialog
          :title="
            buttonDialogType === 1
              ? '新增按钮'
              : buttonDialogType === 2
              ? '查看按钮'
              : '修改按钮'
          "
          :visible.sync="buttonDialogVisible"
          width="600px"
        >
          <el-form
            ref="buttonForm"
            :model="buttonForm"
            :rules="buttonRules"
            label-width="80px"
          >
            <el-form-item label="按钮名称" prop="name">
              <el-input
                type="text"
                v-model="buttonForm.name"
                placeholder="请输入按钮名称"
                :disabled="buttonDialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="按钮编码" prop="code">
              <el-input
                type="text"
                v-model="buttonForm.code"
                placeholder="请输入按钮编码"
                :disabled="buttonDialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sortNum">
              <el-input-number
                v-model="buttonForm.sortNum"
                :min="0"
                placeholder="请输入排序"
                :disabled="buttonDialogType === 2"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="是否禁用" prop="isDisabled">
              <el-switch
                v-model="buttonForm.isDisabled"
                :active-value="1"
                :inactive-value="0"
                :disabled="buttonDialogType === 2"
              ></el-switch>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                v-model="buttonForm.remark"
                placeholder="请输入备注"
                :disabled="buttonDialogType === 2"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="buttonDialogVisible = false">取 消</el-button>
            <el-button
              v-if="buttonDialogType !== 2"
              type="primary"
              @click="handleConfirmButton"
              :loading="buttonConfirmLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  queryPageButton,
  queryPermission,
  addPermission,
  disablePermission,
  enablePermission,
  deletePermission,
  getPermission,
  updatePermission,
} from "@/api/permission";
import { listToTree } from "@/utils/list-to-tree";

export default {
  name: "PermissionMgt",
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("菜单名称不能为空"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("菜单编码不能为空"));
      } else {
        callback();
      }
    };
    const validatePath = (rule, value, callback) => {
      if (!value) {
        callback(new Error("菜单路径不能为空"));
      } else {
        callback();
      }
    };
    const validateIsDisabled = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("是否禁用不能为空"));
      } else {
        callback();
      }
    };
    return {
      permissionList: [],
      tableLoading: false,
      tableSelection: [],
      menuDialogType: 1,
      menuDialogVisible: false,
      menuForm: {
        parentId: 0,
        parentIds: "0,",
        permissionType: 2,
        isDisabled: 0,
      },
      menuRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        path: [{ required: true, trigger: "blur", validator: validatePath }],
        isDisabled: [
          { required: true, trigger: "blur", validator: validateIsDisabled },
        ],
      },
      menuConfirmLoading: false,
      buttonDialogType: 1,
      buttonDialogVisible: false,
      buttonForm: {
        parentId: 0,
        parentIds: "0,",
        permissionType: 3,
        isDisabled: 0,
      },
      buttonRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        isDisabled: [
          { required: true, trigger: "blur", validator: validateIsDisabled },
        ],
      },
      buttonConfirmLoading: false,
      addRoot: false,
      disable: false,
      enable: false,
      deleteButton: false,
      addSub: false,
      addButtonButton: false,
      viewButton: false,
      updateButtonButton: false,
      viewMenu: false,
      updateMenuButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 13;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "addRoot") {
            this.addRoot = true;
          }
          if (button.code === "disable") {
            this.disable = true;
          }
          if (button.code === "enable") {
            this.enable = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "addSub") {
            this.addSub = true;
          }
          if (button.code === "addButton") {
            this.addButtonButton = true;
          }
          if (button.code === "viewButton") {
            this.viewButton = true;
          }
          if (button.code === "updateButton") {
            this.updateButtonButton = true;
          }
          if (button.code === "viewMenu") {
            this.viewMenu = true;
          }
          if (button.code === "updateMenu") {
            this.updateMenuButton = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;

      queryPermission().then((resp) => {
        this.permissionList = listToTree(resp.data);
        this.tableLoading = false;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleAddRoot() {
      this.menuForm = {};
      if (this.$refs.menuForm !== undefined) {
        this.$refs.menuForm.resetFields();
      }
      this.menuForm.parentId = 0;
      this.menuForm.parentIds = "0,";
      this.menuForm.permissionType = 2;
      this.menuForm.isDisabled = 0;
      this.menuDialogType = 1;
      this.menuDialogVisible = true;
    },
    handleConfirmMenu() {
      if (this.menuDialogType === 1) {
        this.addMenu();
      } else if (this.menuDialogType === 3) {
        this.updateMenu();
      }
    },
    handleConfirmButton() {
      if (this.buttonDialogType === 1) {
        this.addButton();
      } else if (this.buttonDialogType === 3) {
        this.updateButton();
      }
    },
    addMenu() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          this.menuConfirmLoading = true;
          const data = this.menuForm;

          addPermission(data)
            .then((resp) => {
              this.$notify({
                title: "新增菜单成功",
                message: resp.message,
                type: "success",
              });
              this.menuConfirmLoading = false;
              this.menuDialogVisible = false;
              this.query();
            })
            .catch((error) => {
              this.menuConfirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    addButton() {
      this.$refs.buttonForm.validate((valid) => {
        if (valid) {
          this.buttonConfirmLoading = true;
          const data = this.buttonForm;

          addPermission(data)
            .then((resp) => {
              this.$notify({
                title: "新增按钮成功",
                message: resp.message,
                type: "success",
              });
              this.buttonConfirmLoading = false;
              this.buttonDialogVisible = false;
              this.query();
            })
            .catch((error) => {
              this.buttonConfirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleDisable() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的权限不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.id);
      }

      disablePermission(data)
        .then((resp) => {
          this.$notify({
            title: "禁用成功",
            message: resp.message,
            type: "success",
          });
          this.query();
        })
        .catch((error) => {});
    },
    handleEnable() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的权限不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.id);
      }

      enablePermission(data)
        .then((resp) => {
          this.$notify({
            title: "启用成功",
            message: resp.message,
            type: "success",
          });
          this.query();
        })
        .catch((error) => {});
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的权限不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }

          deletePermission(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleAddSub(menu) {
      this.menuForm = {};
      if (this.$refs.menuForm !== undefined) {
        this.$refs.menuForm.resetFields();
      }
      this.menuForm.parentId = menu.id;
      this.menuForm.parentIds = menu.parentIds + menu.id + ",";
      this.menuForm.permissionType = 2;
      this.menuForm.isDisabled = 0;
      this.menuDialogType = 1;
      this.menuDialogVisible = true;
    },
    handleAddButton(menu) {
      this.buttonForm = {};
      if (this.$refs.buttonForm !== undefined) {
        this.$refs.buttonForm.resetFields();
      }
      this.buttonForm.parentId = menu.id;
      this.buttonForm.parentIds = menu.parentIds + menu.id + ",";
      this.buttonForm.permissionType = 3;
      this.buttonForm.isDisabled = 0;
      this.buttonDialogType = 1;
      this.buttonDialogVisible = true;
    },
    handleViewMenu(id) {
      const data = id;
      getPermission(data)
        .then((resp) => {
          if (this.$refs.menuForm !== undefined) {
            this.$refs.menuForm.resetFields();
          }
          this.menuForm = resp.data;

          this.menuDialogType = 2;
          this.menuDialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdateMenu(id) {
      const data = id;
      getPermission(data)
        .then((resp) => {
          if (this.$refs.menuForm !== undefined) {
            this.$refs.menuForm.resetFields();
          }
          this.menuForm = resp.data;

          this.menuDialogType = 3;
          this.menuDialogVisible = true;
        })
        .catch((error) => {});
    },
    handleViewButton(id) {
      const data = id;
      getPermission(data)
        .then((resp) => {
          if (this.$refs.buttonForm !== undefined) {
            this.$refs.buttonForm.resetFields();
          }
          this.buttonForm = resp.data;

          this.buttonDialogType = 2;
          this.buttonDialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdateButton(id) {
      const data = id;
      getPermission(data)
        .then((resp) => {
          if (this.$refs.buttonForm !== undefined) {
            this.$refs.buttonForm.resetFields();
          }
          this.buttonForm = resp.data;

          this.buttonDialogType = 3;
          this.buttonDialogVisible = true;
        })
        .catch((error) => {});
    },
    updateMenu() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          this.menuConfirmLoading = true;
          const data = this.menuForm;

          updatePermission(data)
            .then((resp) => {
              this.$notify({
                title: "修改菜单成功",
                message: resp.message,
                type: "success",
              });
              this.menuConfirmLoading = false;
              this.menuDialogVisible = false;
              this.query();
            })
            .catch((error) => {
              this.menuConfirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    updateButton() {
      this.$refs.buttonForm.validate((valid) => {
        if (valid) {
          this.buttonConfirmLoading = true;
          const data = this.buttonForm;

          updatePermission(data)
            .then((resp) => {
              this.$notify({
                title: "修改按钮成功",
                message: resp.message,
                type: "success",
              });
              this.buttonConfirmLoading = false;
              this.buttonDialogVisible = false;
              this.query();
            })
            .catch((error) => {
              this.buttonConfirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
